import { Component, OnInit } from "@angular/core";
import { UserService } from "app/services/user/user.service";
import { ProjectService } from "app/services/project/project.service";

@Component({
	selector: "app-legal-terms",
	templateUrl: "./legal-terms.component.html",
	styleUrls: ["./legal-terms.component.scss"],
})
export class LegalTermsComponent implements OnInit {
	idProject: number = 0;
	legalTerms: string = "";

	constructor(
		private userService: UserService,
		private projectService: ProjectService,
	) {}

	ngOnInit(): void {
		this.getDataUser();
	}

	getDataUser() {
		this.userService.getDataUser().subscribe(
			(data) => {
				let json = JSON.parse(JSON.stringify(data));
				this.idProject = json.proyectos[0].id;
				this.getLegalTerms(this.idProject);
			},
			(error) => {
				console.log(error);
			},
		);
	}

	getLegalTerms(idProject: number) {
		this.projectService.getProjectLegal(idProject).subscribe(
			(data) => {
				let json = JSON.parse(JSON.stringify(data));
				this.legalTerms = json.legal;
			},
			(error) => {
				console.log(error);
			},
		);
	}
}
