import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { TranslateModule } from "@ngx-translate/core";

import { FuseSharedModule } from "@fuse/shared.module";

import { LegalTermsComponent } from "app/main/legal-terms/legal-terms.component";

const routes = [
	{
		path: "legal-terms",
		component: LegalTermsComponent,
	},
];

@NgModule({
	declarations: [LegalTermsComponent],
	imports: [
		RouterModule.forChild(routes),

		MatButtonModule,
		MatCheckboxModule,
		MatFormFieldModule,
		MatIconModule,
		MatInputModule,
		TranslateModule,

		FuseSharedModule,
	],
})
export class LegalTermsModule {}
